<template>
  <div class="add-role">
    <div class="title">编辑角色</div>
    <div class="cell">
      <div class="info">
        <div class="title">基本信息</div>
        <el-form
          size="mini"
          label-position="top"
          ref="form"
          :model="form"
          label-width="80px"
          style="width: 500px"
          :rules="rules"
        >
          <el-form-item
            style="font-size: 12px"
            prop="role_name"
            label="角色名称"
          >
            <el-input v-model="form.role_name"></el-input>
          </el-form-item>
          <el-form-item label="描述" prop="description">
            <el-input
              :autosize="{ minRows: 6, maxRows: 6 }"
              type="textarea"
              v-model="form.description"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="set">
        <div class="title">权限设置</div>
        <div style="width: 500px" >
          <el-tree
            ref="tree"
            :data="NodeData"
            node-key="id"
            :props="props"
            default-expand-all
            :default-checked-keys="checkedData"
            @node-drag-start="handleDragStart"
            @node-drag-enter="handleDragEnter"
            @node-drag-leave="handleDragLeave"
            @node-drag-over="handleDragOver"
            @node-drag-end="handleDragEnd"
            @node-drop="handleDrop"
            :show-checkbox="true"
            :allow-drop="allowDrop"
            :allow-drag="allowDrag"
          >
          </el-tree>
        </div>
        <div style="margin-top: 20px; margin-bottom: 10px">
          <el-button size="mini" type="primary" @click="addRole"
            >确认</el-button
          >
          <el-button size="mini" plain @click="handleBack">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  operatorAdd,
  getRoleInfo,
  nodeList,
  operatorEdit,
} from "@/api/admin.js";
export default {
  data() {
    return {
      form: { id: 0, role_name: "", description: "", auth: [] },
      props: {
        label: "node_name",
      },
      NodeData: [],
      checkedData: [],
      rules: {
        role_name: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
        description: [
          { required: true, message: "请输入描述", trigger: "blur" },
        ],
      },

    };
  },

  mounted() {
    this.getNodeList();
    this.form.id = sessionStorage.ROLEID;
    if (this.form.id != 0) {
      this.getInfo();
    }
  },
  methods: {
    getInfo() {
      getRoleInfo(this.form).then((res) => {
        if (res.code == 1) {
          this.form = res.data;
          res.data.auth.forEach((item) => {
            if (item.checked == 1) {
              this.a(item.id);
 
            }
            if (item.children && item.children.length != 0) {
              item.children.forEach((val) => {
                if (val.checked == 1) {
                  this.a(val);
            
                }
                if (val.children && val.children.length != 0) {
                  val.children.forEach((echo) => {
                    if (echo.checked == 1) {
                      this.a(echo);
      
                    }
                  });
                }
              });
            }
          });
          this.setCheckedNodes(this.checkedData);
        }
      });
    },
    getNodeList() {
      nodeList().then((res) => {
        if (res.code == 1) {
          this.NodeData = res.data;
        }
      });
    },
    addRole() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let arr = [];
    
          this.$refs.tree.getCheckedNodes().forEach((item) => {
            arr.push(item.id);
          });
          this.$refs.tree.getHalfCheckedNodes().forEach(item=>{
               arr.push(item.id);
          })

          this.form.auth = arr.join(",");
          if (this.form.id == 0) {
            operatorAdd({
              ...this.form,
            }).then((res) => {
              if (res.code == 1) {
                this.$message.success("添加角色成功");
                this.$router.replace("roleManagement");
              }
            });
          } else {
            operatorEdit({
              ...this.form,
            }).then((res) => {
              if (res.code == 1) {
                this.$message.success("编辑角色成功");
                this.$router.replace("roleManagement");
              }
            });
          }
        }
      });
    },
    a(id) {
      this.checkedData.push(id);
    },
    setCheckedNodes(arr) {
      this.$nextTick(()=>{
      this.$refs.tree.setCheckedNodes(arr);
      })

    },
    handleBack() {
      this.$router.go(-1);
    },
    handleDragStart(node) {
      console.log("drag start", node);
    },
    handleDragEnter(draggingNode, dropNode) {
      console.log("tree drag enter: ", dropNode.label);
    },
    handleDragLeave(draggingNode, dropNode) {
      console.log("tree drag leave: ", dropNode.label);
    },
    handleDragOver(draggingNode, dropNode) {
      console.log("tree drag over: ", dropNode.label);
    },
    handleDragEnd(draggingNode, dropNode, dropType) {
      console.log("tree drag end: ", dropNode && dropNode.label, dropType);
    },
    handleDrop(draggingNode, dropNode, dropType) {
      console.log("tree drop: ", dropNode.label, dropType);
    },
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.data.label === "二级 3-1") {
        return type !== "inner";
      } else {
        return true;
      }
    },
    allowDrag(draggingNode) {
      return draggingNode.data.label.indexOf("三级 3-2-2") === -1;
    },
  },
};
</script>
<style lang="less">
.add-role {
  .title {
    font-size: 18px;
    padding-bottom: 20px;
  }
  .cell {
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    .info {
      .title {
        font-size: 14px;
      }
    }
    .set {
      .title {
        font-size: 14px;
      }
    }
    .el-form-item__label {
      font-size: 12px;
    }
  }
}
</style>